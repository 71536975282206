<template>
  <div class="user-box">
    <!-- 顶部搜索栏 -->
    <search-form @search="searchList" @add-role="showStatus = 1"></search-form>
    <!-- 角色列表 -->
    <role-table :list="roles" @edit="clickEdit" @delete="handleDel"></role-table>
    <!-- 分页 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
    <!-- 添加表单 -->
    <add-form
      :show-status="showStatus"
      :tree="tree"
      :edit-data="editData"
      @cancel="showStatus = 0"
      @submit-add="submitForm"
    ></add-form>
  </div>
</template>

<script>
// components
import searchForm from './components/search-form';
import roleTable from './components/role-table';
import addForm from './components/add-form';
// api
import service from './api';
import pagination from '@/mixins/pagination';
export default {
  mixins: [pagination],
  data() {
    return {
      roles: [],
      page: {},
      showStatus: 0,
      editData: {},
      searchData: {},
      tree: [] // 权限树形结构
    };
  },
  components: {
    searchForm,
    roleTable,
    addForm
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      let initParam = { pageNum: 1 };
      this.getList(initParam);
      this.getPermits();
    },
    // 获取角色列表
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.cureentPage = pageNum;

      return service.getRoleList(params).then(res => {
        let { page = {}, list = [] } = res;
        this.page = page;
        this.roles = list.map((item, index) => {
          // 权限名称
          item.limits = item.jurisdiction.reduce((prev, cur) => ((prev = prev + '，' + cur.name), prev), '').slice(1);

          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
          return item;
        });
      });
    },
    // 搜索列表
    searchList(data) {
      let params = { pageNum: 1, ...data };
      this.searchData = data;
      return this.getList(params);
    },
    // 编辑权限
    clickEdit(row) {
      this.showStatus = 2;
      this.editData = row;
    },
    // 获取权限设置
    getPermits() {
      return service.getTree().then(res => {
        this.tree = res.reduce((tprev, tcur) => {
          let { son = [], id = '', name = '' } = tcur;
          tprev.push({
            id,
            label: name,
            children: son.reduce((prev, cur) => (prev.push({ id: cur.id, label: cur.name }), prev), [])
          });
          return tprev;
        }, []);
      });
    },
    submitForm(data) {
      let { showStatus, editData = {}, currentPage } = this;
      showStatus === 1
        ? this.handleSubmit(service.add(data), { pageNum: 1 })
        : this.handleSubmit(service.edit({ roleId: editData.id, ...data }), { pageNum: currentPage });
    },
    // 统一处理提交
    handleSubmit(r, refreshParam) {
      return r.then(res => {
        this.showStatus = 0;
        this.$message({ type: 'success', message: '操作成功！', offset: 400 });
        this.getList(refreshParam);
      });
    },
    // 删除角色
    handleDel(row) {
      this.$alert(`确定将【${row.role_name || ''}】永久删除吗？`, '提示', { showCancelButton: true }).then(() => {
        return service.del({ roleId: row.id }).then(res => {
          this.$notify({ type: 'success', message: '删除成功！' });
          this.init();
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.user-box {
}
</style>
