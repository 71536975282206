<template>
  <div class="role-table">
    <el-table
      :data="list"
      border
      style="width: 100%"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '8px 0'}"
    >
      <!-- <el-table-column prop="tempId" label="ID" align="center" width="60"></el-table-column> -->
      <el-table-column prop="role_name" label="角色名称" align="center" fixed="left"></el-table-column>
      <el-table-column prop="limits" label="权限" align="center" min-width="200"></el-table-column>
      <el-table-column
        prop="created_at"
        label="添加时间"
        align="center"
        width="165"
        :formatter="dateFormat"
        sortable
      ></el-table-column>
      <!-- <el-table-column prop="status" label="当前状态" align="center" width="80">
        <template slot-scope="scope">
          <el-tag
            size="mini"
            effect="dark"
            :type="scope.row.status ? 'danger' : 'success'"
          >{{ scope.row.status ? '禁用' : '正常' }}</el-tag>
        </template>
      </el-table-column>-->
      <el-table-column label="操作" align="center" width="110" fixed="right">
        <template slot-scope="scope">
          <template v-if="scope.row.id > 1000">
            <el-button
              type="success"
              size="mini"
              icon="el-icon-edit"
              circle
              @click="$emit('edit', scope.row )"
            ></el-button>
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              circle
              @click="$emit('delete', scope.row)"
            ></el-button>
          </template>
          <span class="ft-sz-13 ft-bold" v-else>系统内置，不可操作</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import date from '@/mixins/date';
export default {
  mixins: [date],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
